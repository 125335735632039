import React, { Component } from "react";
import { Link } from "react-router-dom";
import Orders from "../services/Orders";
import Helper from "../services/Helper";
import $ from "jquery";

import Icon from "./Icon.jsx";
import Session from "../services/Session.jsx";
import { Content } from "./Content.jsx";
import ContentHelper from "../services/ContentHelper.jsx";
import { APIRequest } from "../services/API.js";
import TPVWidget from "./TPVWidget.jsx";
import Slider from "./Slider.jsx";
import LoadingIndicator from "./LoadingIndicator.jsx";
import Events from "../services/Events.jsx";

class Pago extends Component {
	constructor(props) {
		super(props);

		this.state = {
			order_details: null,
			order_error: null,
			order_completed: false,
			order_in_progress: true,
			order_data: {},
			user_name: "",
			user_surname: "",
			event: null,
			booking: null,
			placeholder_campo_alergias: "",
			codigo_descuento: null,
			loadingInProgress: false
		};

		ContentHelper.GetString("placeholder-campo-alergias").then((text) => {
			this.setState({
				placeholder_campo_alergias: text
			});
		});

		Session.GetSession().then((session) => {
			if (session && session.id) {
				this.setState({
					user_name: session["name"],
					user_surname: session["surname"]
				});
			} else {
				Session.RequireLogin();
			}
		});
	}

	componentDidMount() {
		$("header").addClass("simple");
		let booking = Session.GetBookingData();
		let event = booking.selected_event;

		if (event && booking) {
			event = JSON.parse(event);
			Session.SetBookingData("selected_event_id", event.id);
			const bookingData = Session.GetBookingData();

			if (!bookingData.selected_slot) {
				window.location.href = "/venue/" + event.slug;
				return;
			}

			this.setState({
				event: event,
				booking: bookingData,
				order_error: null
			});
		} else {
			this.setState({
				order_error: null,
				order_in_progress: false
			});

			window.location.href = "/";
		}

		this.getOrderDetails();

		window.addEventListener("scroll", this.onScrollEvent);
	}

	componentDidUpdate() {
		let width = 0;
		$(".preorder-details-line:not(.secondary) .preorder-quantity").each(function () {
			if ($(this).width() > width) {
				width = $(this).width();
			}
		});
		$(".preorder-details-line:not(.secondary) .preorder-quantity").width(width);
	}

	getOrderDetails() {
		return new Promise((resolve, reject) => {
			const selected_event = JSON.parse(Session.GetBookingData("selected_event"));
			Session.SetBookingData("selected_full_slot", Helper.RenderFullTimeSlot(Session.GetBookingData("selected_slot"), selected_event.mean_lunch_time, selected_event.mean_dining_time, selected_event));

			Orders.getOrderDetails(Session.GetBookingData()).then((response) => {
				if (response.status) {
					const order_completed = response.data.order && response.data.order.payment_status === "1";
					const codigoDescuento = response.data.order ? parseInt(response.data.order.discount_id) : null;

					this.setState({
						order_details: response.data,
						order_completed,
						event_data: response.data.event,
						order_data: response.data.order,
						codigo_descuento: isNaN(codigoDescuento) ? null : codigoDescuento,
						order_in_progress: false
					});

					if (order_completed) {
						window.location.href = "/reserva-completada";
					}

					if (!sessionStorage.getItem("current_order_id") || sessionStorage.getItem("current_order_id") == 0) {
						APIRequest("payment/create-order", Session.GetBookingData()).then((response) => {
							sessionStorage.setItem("current_order_id", response.data);
							Orders.getOrderDetails(Session.GetBookingData()).then((response) => {
								const codigoDescuento = parseInt(response.data.order.discount_id);
								this.setState({
									order_details: response.data,
									order_data: response.data.order,
									codigo_descuento: isNaN(codigoDescuento) ? null : codigoDescuento
								});

								resolve(response.data.order);
							});
						});
					} else {
						resolve(response.data.order);
					}
				} else {
					this.setState({
						order_error: response.data.error,
						order_in_progress: false
					});

					reject(response.data.error);
				}
			});
		});
	}

	componentWillUnmount() {
		window.removeEventListener("scroll", this.onScrollEvent);
		$("header").removeClass("simple");
	}

	onScrollEvent(e) {
		if ($(window).scrollTop() > 0) {
			if (!$(".booking-sidebar").hasClass("floated")) {
				$(".booking-sidebar")
					.css({
						position: "fixed",
						top: 150 + "px",
						left: $(".booking-sidebar").offset().left + "px",
						width: $(".booking-sidebar").outerWidth()
					})
					.addClass("floated");
			}
		} else {
			if ($(".booking-sidebar").hasClass("floated")) {
				$(".booking-sidebar")
					.css({
						position: "",
						top: "",
						left: "",
						width: ""
					})
					.removeClass("floated");
			}
		}
	}

	render() {
		if (!this.state.event || !this.state.booking || !this.state.order_details || !this.state.order_details.order || !sessionStorage.getItem("current_order_id")) return <LoadingIndicator />;

		return (
			<div className="pago">
				{this.state.loadingInProgress && <LoadingIndicator />}
				<div className="container">
					<div className="row">
						<div className="col-md-8 col-sm-12 order-container">
							<div className="box">
								<div className={this.state.order_error ? "hidden" : ""}>
									<h3>{this.state.user_name}, completa tu reserva</h3>
									{Helper.IsResponsive() && (
										<div className="change-order-link">
											<Link to={"/venue/" + this.state.event.slug}>¿Quieres modificarla?</Link>
										</div>
									)}
								</div>
								{this.renderOrderDetails()}
							</div>
							{Helper.IsResponsive() && this.renderCajaDescuento()}
							<div className={"box-alergia mt" + (this.state.order_error ? " hidden" : "")}>
								<label>
									<Content slug="etiqueta-campo-alergias" />
								</label>
								<textarea disabled={this.state.order_completed} id="notas-pedido" rows={5} placeholder={this.state.order_data.comments || this.state.placeholder_campo_alergias} defaultValue={this.state.order_data ? this.state.order_data.comments : ""}></textarea>
							</div>

							<TPVWidget order={this.state.order_data.id} amount={this.state.order_details.total} />

							<button disabled={this.state.order_in_progress} className={"btn btn-brown btn-pagar" + (this.state.order_error || this.state.order_completed ? " hidden" : "")} onClick={this.completarReserva.bind(this)}>
								Pagar
							</button>
							{this.state.accept_terms_error && <div className="accept-terms-error">Debes aceptar los términos de uso y la política de privacidad para continuar.</div>}
							<img alt="" src="/static/images/pago.png" className="formas-pago mobile-only" />
							<div className={"disclaimer" + (this.state.order_error ? " hidden" : "")}>
								<input type="checkbox" required className="accept-terms"></input> Confirmo que he leído y acepto los{" "}
								<a rel="noreferrer" href="/terminos-y-condiciones" target="_blank">
									términos de uso
								</a>{" "}
								y la{" "}
								<a rel="noreferrer" href="/politica-de-privacidad" target="_blank">
									política de privacidad
								</a>
								.
							</div>
							<img alt="" src="/static/images/pago.png" className="formas-pago desktop-only" />
						</div>
						{this.renderBookingSidebar()}
					</div>
				</div>
			</div>
		);
	}

	renderBookingSidebar() {
		if (this.state.order_error) return null;

		const hasSlider = this.state.order_details.order.extra_events && this.state.order_details.order.extra_events.length > 0;
		let slides = [];

		if (hasSlider) {
			slides.push({
				background: "eventos/" + (this.state.order_details.event.image_url || this.state.order_details.event.event_image_url),
				title: this.state.order_details.event.name
			});
			this.state.order_details.order.extra_events.forEach((item) => {
				slides.push({
					background: "eventos/" + item.header_image_url,
					title: item.name
				});
			});
		}

		return (
			<div className="col-md-4 booking-sidebar">
				<div className="box event-information">
					{!hasSlider && (
						<div
							className="event-image"
							style={{
								backgroundImage: "url(static/images/eventos/" + (this.state.order_details.event.image_url || this.state.order_details.event.event_image_url) + ")"
							}}
						/>
					)}
					{hasSlider && (
						<Slider
							style={{
								overflow: "hidden",
								width: 300,
								height: 200,
								minHeight: 200
							}}
							height={200}
							slides={slides}
						/>
					)}
					<div className="booking-information">
						<div className="change-order-link">
							<Link to={"/venue/" + this.state.event.slug}>¿Quieres modificar tu reserva?</Link>
						</div>
					</div>
				</div>

				{this.renderCajaDescuento()}
			</div>
		);
	}

	onGotOrderId(order_id) {
		if (!this.state.order_details.order) {
			this.getOrderDetails();
		} else {
			const new_order_details = { ...this.state.order_details };
			new_order_details.order.id = order_id;
			this.setState(new_order_details);
		}
	}

	renderCajaDescuento() {
		return (
			<div className="box caja-descuento">
				{this.state.codigo_descuento != null && this.state.codigo_descuento !== 0 && (
					<React.Fragment>
						<div>¡Código descuento aplicado!</div>
						<button
							className="btn btn-brown aplicar-codigo-descuento"
							onClick={(e) => {
								APIRequest("payment/unapply-discount-code", {
									order_id: this.state.order_data.id
								}).then((response) => {
									this.setState({
										codigo_descuento: null,
										codigo_descuento_input: null,
										error_codigo_descuento: null,
										loadingInProgress: true
									});
									this.getOrderDetails().then(() => {
										this.setState({ loadingInProgress: false });
									});
								});
							}}>
							Eliminar descuento
						</button>
					</React.Fragment>
				)}
				{(this.state.codigo_descuento === null || this.state.codigo_descuento === 0) && (
					<React.Fragment>
						<div>¿Tienes un código de descuento?</div>
						<input
							className="codigo-descuento"
							onChange={(e) => {
								this.setState({ codigo_descuento_input: e.target.value });
							}}></input>
						{this.state.error_codigo_descuento && <div className="error-codigo-descuento">{this.state.error_codigo_descuento}</div>}
						<button
							className="btn btn-brown aplicar-codigo-descuento"
							onClick={(e) => {
								this.setState({ loadingInProgress: true });
								APIRequest("payment/apply-discount-code", {
									code: this.state.codigo_descuento_input,
									order_id: this.state.order_data.id
								}).then((response) => {
									if (response.status) {
										this.setState({
											error_codigo_descuento: null,
											codigo_descuento: this.state.codigo_descuento_input
										});
										this.getOrderDetails().then(() => {
											this.setState({ loadingInProgress: false });
										});
									} else {
										this.setState({
											error_codigo_descuento: "Este código no parece válido.",
											loadingInProgress: false
										});
									}
								});
							}}>
							Aplicar
						</button>
					</React.Fragment>
				)}
			</div>
		);
	}

	completarReserva() {
		if (!$(".accept-terms").prop("checked")) {
			this.setState({ accept_terms_error: true });
			return;
		}

		this.setState({
			order_in_progress: true,
			accept_terms_error: false
		});

		Orders.UpdateOrderPreferences(this.state.order_data.id, $("#notas-pedido").val(), $("#acepta-recibir").prop("checked")).then(() => {
			$("#tpv-form").trigger("submit");
		});
	}

	renderOrderDetails() {
		if (this.state.order_error) {
			return (
				<Link className="btn btn-brown" to="/">
					Volver
				</Link>
			);
		}
		if (!this.state.order_details) return null;

		const eventPrice = this.state.order_details.event.availability.price != -1 ? this.state.order_details.event.availability.price : this.state.order_details.event.price;
		const selectedDate = Helper.CreateDateCompatible(this.state.booking.selected_date);

		return (
			<div className="order-details">
				<div className="order-details-preorder">
					<div className="preorder-details-line title">
						<div className="preorder-name">
							<b>
								{this.state.order_details.event.nombre} | {this.state.event.name}
							</b>
							<div className="details-address">{this.state.order_details.event.availability.address_alt ? this.state.order_details.event.availability.address_alt : this.state.order_details.event.address}</div>
						</div>
						<div className="preorder-amount">{Helper.FormatAmount(((this.state.order_details.event.full_booking ? 1 : this.state.booking.pax) * eventPrice) / 100)}</div>
					</div>
					<div className="preorder-details-line secondary details">
						<Icon pixelSize={25} name="calendar" />
						{Helper.FormatISODate(this.state.booking.selected_date)}
						{this.state.booking.selection_length > 1 && <span>&nbsp;a {Helper.FormatISODate(Helper.GetISODate(new Date(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate() + this.state.booking.selection_length - 1)))}</span>} <Icon pixelSize={25} name="clock" style={{ marginLeft: "1em", marginRight: "0.5em" }} />
						{this.state.order_details.event.availability.slot.split(" ")[1].substring(0, 5)}
						{Helper.RenderDurationEndTime(this.state.order_details.event.availability.slot.split(" ")[1], this.state.order_details.event.availability.duration || this.state.order_details.event.duration, " a ")}
						<Icon pixelSize={25} name="users" style={{ marginLeft: "1em", marginRight: "0.5em" }} />
						{this.state.booking.pax}
					</div>

					{this.state.order_details.order.preorder_selection &&
						this.state.order_details.order.preorder_selection.map((item, idx) => {
							if (item.quantity <= 0) {
								return null;
							}

							return (
								<div className="preorder-details-line secondary" key={idx}>
									<div className="preorder-quantity">{item.quantity}</div>
									<div className="preorder-name">{item.name}</div>
									<div className="preorder-amount">{Helper.FormatAmount((item.quantity * item.price) / 100)}</div>
								</div>
							);
						})}
					{this.state.order_details.order.extra_events &&
						this.state.order_details.order.extra_events.map((item, idx) => {
							if (item.quantity <= 0) {
								return null;
							}

							const isoDate = item.slot.split(" ")[0];
							const slot = item.slot.split(" ")[1].substring(0, 5);
							const selectedDate = Helper.CreateDateCompatible(isoDate);

							return (
								<React.Fragment key={"event-" + idx}>
									<div className="preorder-details-line title">
										<div className="preorder-name">
											<b>
												{item.event_name} | {item.name}
											</b>
											<div className="details-address">{item.address}</div>
										</div>
										<div className="preorder-amount">{Helper.FormatAmount(((item.full_booking ? 1 : item.quantity) * item.price) / 100)}</div>
									</div>
									<div className="preorder-details-line secondary details">
										<Icon pixelSize={25} name="calendar" />
										{Helper.FormatISODate(isoDate)}
										{item.selection_length > 1 && <span>&nbsp;a {Helper.FormatISODate(Helper.GetISODate(new Date(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate() + item.selection_length - 1)))}</span>} <Icon pixelSize={25} name="clock" style={{ marginLeft: "1em", marginRight: "0.5em" }} />
										{slot}
										{Helper.RenderDurationEndTime(slot, item.duration, " a ")} <Icon pixelSize={25} name="users" style={{ marginLeft: "1em", marginRight: "0.5em" }} />
										{item.quantity}
									</div>

									{item.extras?.map((extra, idx) => {
										return (
											<div className="preorder-details-line secondary" key={"extra-" + idx}>
												<div className="preorder-quantity">{extra.qty}</div>
												<div className="preorder-name">{extra.name}</div>
												<div className="preorder-amount">{Helper.FormatAmount((extra.qty * extra.price) / 100)}</div>
											</div>
										);
									})}
								</React.Fragment>
							);
						})}
					<div className="change-order-link">
						<Link to={"/venue/" + this.state.event.slug + "/extras"}>¿Quieres modificar tu selección?</Link>
					</div>
				</div>
				<div className="order-details-total">
					<div>{this.state.order_details.label} (IVA incluido)</div>
					<div>
						{this.state.order_details.order.before_discounts != this.state.order_details.total && <span className="before-discounts">{Helper.FormatAmount(this.state.order_details.order.before_discounts / 100)}</span>}
						{Helper.FormatAmount(this.state.order_details.total / 100)}
					</div>
				</div>
			</div>
		);
	}

	renderOrderType() {
		const preorderPath = Session.GetBookingData("preorder_path");
		const mealTime = Events.GetMealTimeForSlot(this.state.event, Session.GetBookingData("selected_full_slot"));

		return (
			<div className="order-type">
				<div>{preorderPath === "ticket" && <b>Reservar mesa</b>}</div>
				<div>{preorderPath === "preorder" && <b>Reservar mesa + Gastronomía</b>}</div>
				<div>{preorderPath === "takeaway" && <b>Pedir take-away</b>}</div>
				<div>{preorderPath === "delivery" && <b>Pedir delivery</b>}</div>
				{mealTime > 0 && <div>Tiempo de mesa {mealTime} min</div>}
			</div>
		);
	}
}

export default Pago;
