import { useEffect, useState, useCallback, useContext } from "react";

import EditorSlider from "./EditorSlider";
import ImageUpload from "./ImageUpload";
import VideoUpload from "./VideoUpload";
import LoadingIndicator from "./LoadingIndicator";
import AdminBar from "./AdminBar";
import Field from "./Field";

import ContentHelper from "../services/ContentHelper";
import Bloque from "./AdminBloque";
import AdminService from "../services/Admin";
import { DialogContext } from "../context/DialogContext";

export default function AdminHome(props) {
	const [sliderData, setSliderData] = useState({});
	const [imagenHome1, setImagenHome1] = useState(null);
	const [subtituloHome1, setSubtituloHome1] = useState(null);
	const [textoHome1, setTextoHome1] = useState(null);
	const [logosHome1, setLogosHome1] = useState(null);

	const [imagenHome2, setImagenHome2] = useState(null);
	const [subtituloHome2, setSubtituloHome2] = useState(null);
	const [textoHome2, setTextoHome2] = useState(null);
	const [logosHome2, setLogosHome2] = useState(null);

	const [imagenHome3, setImagenHome3] = useState(null);
	const [subtituloHome3, setSubtituloHome3] = useState(null);
	const [textoHome3, setTextoHome3] = useState(null);
	const [logosHome3, setLogosHome3] = useState(null);

	const [videoPosterFrame, setVideoPosterFrame] = useState(null);
	const [videoFile, setVideoFile] = useState(null);

	const [loadingInProgress, setLoadingInProgress] = useState(true);

	const dialogContext = useContext(DialogContext);

	useEffect(() => {
		ContentHelper.GetString("home-imagen-paso-1").then((url) => setImagenHome1(url));
		ContentHelper.GetString("home-subtitulo-paso-1").then((url) => setSubtituloHome1(url));
		ContentHelper.GetString("home-texto-paso-1").then((url) => setTextoHome1(url));

		Promise.all([ContentHelper.GetString("home-paso-1-logo-1"), ContentHelper.GetString("home-paso-1-logo-2"), ContentHelper.GetString("home-paso-1-logo-3")]).then(([a, b, c]) => {
			setLogosHome1([a, b, c]);
		});

		ContentHelper.GetString("home-imagen-paso-2").then((url) => setImagenHome2(url));
		ContentHelper.GetString("home-titulo-paso-2").then((url) => setSubtituloHome2(url));
		ContentHelper.GetString("home-texto-paso-2").then((url) => setTextoHome2(url));

		Promise.all([ContentHelper.GetString("home-paso-2-logo-1"), ContentHelper.GetString("home-paso-2-logo-2"), ContentHelper.GetString("home-paso-2-logo-3"), ContentHelper.GetString("home-paso-2-logo-4"), ContentHelper.GetString("home-paso-2-logo-5"), ContentHelper.GetString("home-paso-2-logo-6")]).then(([a, b, c, d, e, f]) => {
			setLogosHome2([a, b, c, d, e, f]);
		});

		ContentHelper.GetString("home-imagen-paso-3").then((url) => setImagenHome3(url));
		ContentHelper.GetString("home-titulo-paso-3").then((url) => setSubtituloHome3(url));
		ContentHelper.GetString("home-texto-paso-3").then((url) => setTextoHome3(url));

		Promise.all([ContentHelper.GetString("home-paso-3-logo-1"), ContentHelper.GetString("home-paso-3-logo-2"), ContentHelper.GetString("home-paso-3-logo-3")]).then(([a, b, c]) => {
			setLogosHome3([a, b, c]);
		});

		Promise.all([ContentHelper.GetString("video-home-poster-frame"), ContentHelper.GetString("video-home-file")]).then(([poster, video]) => {
			setVideoPosterFrame("home/" + poster);
			setVideoFile("home/" + video);
		});

		document.body.classList.add("bg-grey");

		return () => {
			document.body.classList.remove("bg-grey");
		};
	}, []);

	const save = useCallback((notifySaved) => {
		dialogContext.openDialog("¿Quieres guardar los cambios?", "Sí", "No", (confirmed) => {
			if (!confirmed) {
				notifySaved(false);
				return;
			}

			AdminService.SaveContentMultiple({
				"configuracion-slides": JSON.stringify(sliderData),
				"home-imagen-paso-1": imagenHome1,
				"home-subtitulo-paso-1": subtituloHome1,
				"home-texto-paso-1": textoHome1,
				"home-paso-1-logo-1": logosHome1[0],
				"home-paso-1-logo-2": logosHome1[1],
				"home-paso-1-logo-3": logosHome1[2],
				"home-imagen-paso-2": imagenHome2,
				"home-titulo-paso-2": subtituloHome2,
				"home-texto-paso-2": textoHome2,
				"home-paso-2-logo-1": logosHome2[0],
				"home-paso-2-logo-2": logosHome2[1],
				"home-paso-2-logo-3": logosHome2[2],
				"home-paso-2-logo-4": logosHome2[3],
				"home-paso-2-logo-5": logosHome2[4],
				"home-paso-2-logo-6": logosHome2[5],
				"home-imagen-paso-3": imagenHome3,
				"home-titulo-paso-3": subtituloHome3,
				"home-texto-paso-3": textoHome3,
				"home-paso-3-logo-1": logosHome3[0],
				"home-paso-3-logo-2": logosHome3[1],
				"home-paso-3-logo-3": logosHome3[2],
				"video-home-poster-frame": videoPosterFrame?.match(/home\/(.*)/)[1],
				"video-home-file": videoFile?.match(/home\/(.*)/)[1]
			}).then(() => {
				notifySaved(true);
			});
		});
	}, [sliderData, imagenHome1, subtituloHome1, textoHome1, logosHome1, logosHome1, logosHome1, imagenHome2, subtituloHome2, textoHome2, logosHome2, logosHome2, logosHome2, logosHome2, logosHome2, logosHome2, imagenHome3, subtituloHome3, textoHome3, logosHome3, logosHome3, logosHome3, videoPosterFrame, videoFile]);

	return (
		<div className="admin-page admin-home">
			<AdminBar
				onSave={save}
			/>
			<Bloque>
				<EditorSlider slug="configuracion-slides" onChange={(sliderData) => setSliderData(sliderData)} />
			</Bloque>
			<Bloque className="columnas">
				<div className="columna">
					<div className="container">
						<div className="row">
							<div className="col">{imagenHome1 !== null && <ImageUpload initialValue={imagenHome1} onChange={(value) => setImagenHome1(value)} />}</div>
						</div>
						<div className="row">
							<div className="col">{subtituloHome1 !== null && <Field type="string" defaultValue={subtituloHome1} onChange={(value) => setSubtituloHome1(value)} />}</div>
						</div>
						<div className="row">
							<div className="col">{textoHome1 !== null && <Field type="text" defaultValue={textoHome1} onChange={(value) => setTextoHome1(value)} />}</div>
						</div>
						{logosHome1 && (
							<div className="row logos logos-home-1">
								<div className="col">
									<ImageUpload
										initialValue={logosHome1[0]}
										onChange={(value) => {
											const newLogosHome = [...logosHome1];
											newLogosHome[0] = value;
											setLogosHome1(newLogosHome);
										}}
									/>
								</div>
								<div className="col">
									<ImageUpload
										initialValue={logosHome1[1]}
										onChange={(value) => {
											const newLogosHome = [...logosHome1];
											newLogosHome[1] = value;
											setLogosHome1(newLogosHome);
										}}
									/>
								</div>
								<div className="col">
									<ImageUpload
										initialValue={logosHome1[2]}
										onChange={(value) => {
											const newLogosHome = [...logosHome1];
											newLogosHome[2] = value;
											setLogosHome1(newLogosHome);
										}}
									/>
								</div>
							</div>
						)}
					</div>
				</div>
				<div className="columna">
					<div className="container">
						<div className="row">
							<div className="col">{subtituloHome2 !== null && <Field type="string" defaultValue={subtituloHome2} onChange={(value) => setSubtituloHome2(value)} />}</div>
						</div>
						<div className="row">
							<div className="col">{textoHome2 !== null && <Field type="text" defaultValue={textoHome2} onChange={(value) => setTextoHome2(value)} />}</div>
						</div>
						{logosHome2 && (
							<div className="row logos logos-home-2">
								<div className="col">
									<ImageUpload
										initialValue={logosHome2[0]}
										onChange={(value) => {
											const newLogosHome = [...logosHome2];
											logosHome2[0] = value;
											setLogosHome2(newLogosHome);
										}}
									/>
								</div>
								<div className="col">
									<ImageUpload
										initialValue={logosHome2[1]}
										onChange={(value) => {
											const newLogosHome = [...logosHome2];
											logosHome2[1] = value;
											setLogosHome2(newLogosHome);
										}}
									/>
								</div>
								<div className="col">
									<ImageUpload
										initialValue={logosHome2[2]}
										onChange={(value) => {
											const newLogosHome = [...logosHome2];
											logosHome2[2] = value;
											setLogosHome2(newLogosHome);
										}}
									/>
								</div>
							</div>
						)}
						{logosHome2 && (
							<div className="row logos logos-home-2">
								<div className="col">
									<ImageUpload
										initialValue={logosHome2[3]}
										onChange={(value) => {
											const newLogosHome = [...logosHome2];
											logosHome2[3] = value;
											setLogosHome2(newLogosHome);
										}}
									/>
								</div>
								<div className="col">
									<ImageUpload
										initialValue={logosHome2[4]}
										onChange={(value) => {
											const newLogosHome = [...logosHome2];
											logosHome2[4] = value;
											setLogosHome2(newLogosHome);
										}}
									/>
								</div>
								<div className="col">
									<ImageUpload
										initialValue={logosHome2[5]}
										onChange={(value) => {
											const newLogosHome = [...logosHome2];
											logosHome2[5] = value;
											setLogosHome2(newLogosHome);
										}}
									/>
								</div>
							</div>
						)}
						<div className="row">
							<div className="col">{imagenHome2 !== null && <ImageUpload initialValue={imagenHome2} onChange={(value) => setImagenHome2(value)} />}</div>
						</div>
					</div>
				</div>
				<div className="columna">
					<div className="container">
						<div className="row">
							<div className="col">{imagenHome3 !== null && <ImageUpload initialValue={imagenHome3} onChange={(value) => setImagenHome2(value)} />}</div>
						</div>
						<div className="row">
							<div className="col">{subtituloHome3 !== null && <Field type="string" defaultValue={subtituloHome3} onChange={(value) => setSubtituloHome3(value)} />}</div>
						</div>
						<div className="row">
							<div className="col">{textoHome3 !== null && <Field type="text" defaultValue={textoHome3} onChange={(value) => setTextoHome3(value)} />}</div>
						</div>
						{logosHome3 && (
							<div className="row logos logos-home-3">
								<div className="col">
									<ImageUpload
										initialValue={logosHome3[0]}
										onChange={(value) => {
											const newLogosHome = [...logosHome3];
											newLogosHome[0] = value;
											setLogosHome3(newLogosHome);
										}}
									/>
								</div>
								<div className="col">
									<ImageUpload
										initialValue={logosHome3[1]}
										onChange={(value) => {
											const newLogosHome = [...logosHome3];
											newLogosHome[1] = value;
											setLogosHome3(newLogosHome);
										}}
									/>
								</div>
								<div className="col">
									<ImageUpload
										initialValue={logosHome3[2]}
										onChange={(value) => {
											const newLogosHome = [...logosHome3];
											newLogosHome[2] = value;
											setLogosHome3(newLogosHome);
										}}
									/>
								</div>
							</div>
						)}
					</div>
				</div>
			</Bloque>
			<Bloque className="columnas">
				{videoPosterFrame !== null && <ImageUpload sizeMode="cover" initialValue={videoPosterFrame} onChange={(value) => setVideoPosterFrame(value)} />}
				{videoFile !== null && <VideoUpload initialValue={videoFile} onChange={(value) => setVideoFile(value)} />}
			</Bloque>
		</div>
	);
}
