import {
    Chart as ChartJS,
    LinearScale,
    CategoryScale,
    BarElement,
    ArcElement,
    Legend,
    Tooltip
} from 'chart.js';

import { Bar, Doughnut } from 'react-chartjs-2';

ChartJS.register(CategoryScale, LinearScale, BarElement, ArcElement);

export default function HorizontalBarChart(props) {
    function getOrCreateTooltip(chart) {
        let tooltipEl = chart.canvas.parentNode.querySelector('div');
        
        if (!tooltipEl) {
            tooltipEl = document.createElement('div');
            tooltipEl.style.pointerEvents = 'none';
            tooltipEl.classList.add("tooltip");	
            tooltipEl.style.position = 'absolute';
            tooltipEl.style.transform = 'translate(-50%, 0)';
            tooltipEl.style.transition = 'all .3s';
            chart.canvas.parentNode.appendChild(tooltipEl);
        }
        
        return tooltipEl;
    };
    
    return <div className="chart-container horizontal-bar-chart">
                <div>
                    <Bar
                        data={{
                            labels: ['País'],
                            datasets: [
                                {
                                    label: 'Restaurantes',
                                    data: [40, 10, 15, 25, 10, 25, 10],
                                    borderWidth: 1,
                                    backgroundColor: '#36a2eb',
                                },
                                {
                                    label: 'Alojamientos',
                                    data: [40, 10, 15, 10, 25, 25, 10],
                                    borderWidth: 1,
                                    backgroundColor: '#4bc0c0',
                                },
                                {
                                    label: 'Cultura',
                                    data: [40, 10, 25, 15, 10, 25, 10],
                                    borderWidth: 1,
                                    backgroundColor: '#ffcd56',
                                },
                                {
                                    label: 'Experiencias',
                                    data: [10, 30, 15, 25, 10, 25, 30],
                                    borderWidth: 1,
                                    backgroundColor: '#ff9f40',
                                },
                                {
                                    label: 'Excursiones',
                                    data: [40, 15, 25, 20, 10, 15, 10],
                                    borderWidth: 1,
                                    backgroundColor: '#ff6384',
                                },
                            ]
                        }}
                        options={{
                            maintainAspectRatio: false,
                            responsive: true,
                            indexAxis: "y",
                            scales: {
                                x: {
                                    display: false,
                                    stacked: true
                                },
                                y: {
                                    display: false,
                                    stacked: true
                                }
                            },
                            plugins: {
                                tooltip: {
                                    enabled: false,
                                    position: 'nearest',
                                    external: (context) => {
                                        // Tooltip Element
                                        const {chart, tooltip} = context;
                                        const tooltipEl = getOrCreateTooltip(chart);
                                        
                                        // Hide if no tooltip
                                        if (tooltip.opacity === 0) {
                                            tooltipEl.style.opacity = 0;
                                            return;
                                        }
                                            
                                        while (tooltipEl.firstChild) {
                                            tooltipEl.removeChild(tooltipEl.firstChild);
                                        }
                                        
                                        // Set Text
                                        if (tooltip.body) {
                                            tooltipEl.innerHTML = '<span>' + tooltip.dataPoints[0].dataset.label + '</span><span>|</span><span>' + tooltip.dataPoints[0].dataset.data[tooltip.dataPoints[0].dataIndex] + '%</span>';
                                        }
                                        
                                        const {offsetLeft: positionX, offsetTop: positionY} = chart.canvas;
                                        
                                        // Display, position, and set styles for font
                                        tooltipEl.style.opacity = 1;
                                        tooltipEl.style.left = positionX + tooltip.caretX + 'px';
                                        tooltipEl.style.top = positionY + tooltip.caretY - 50 + 'px';
                                    }
                                }
                            }
                        }}
                        plugins={[ Tooltip ]}
                    />
                </div>
            </div>;
}