import React, { useCallback, useEffect, useState, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import ContentHelper from "../services/ContentHelper";
import Helper from "../services/Helper";
import Events from "../services/Events";
import Session from "../services/Session";
import Icon from "./Icon";
import LoadingIndicator from "./LoadingIndicator";
import PreorderTicket from "./PreorderTicket";
import PreorderSelector from "./PreorderSelector";

import { PreorderSelectionContext } from "../context/PreorderSelectionContext";

import $ from "jquery";

export default function Preorder(props) {
	const params = useParams();
	const page = params.page;
	const slug = params.slug;

	const [isExtraEventsSelection, setIsExtraEventsSelection] = useState(false);
	const [event, setEvent] = useState(null);
	const [booking, setBooking] = useState(Session.GetBookingData());
	const [total, setTotal] = useState(0);
	const [formattedTotal, setFormattedTotal] = useState();
	const [preorderSelection, setPreorderSelection] = useState(page == "otros" ? Session.GetBookingData("extra_events") || [] : Session.GetBookingData("preorder_selection") || []);
	const [negocios, setNegocios] = useState([]);
	const [logo, setLogo] = useState(<></>);

	const preorderSelectorRef = useRef(null);

	const changeSelection = useCallback(
		(newSelection) => {
			setPreorderSelection(newSelection);
		},
		[setPreorderSelection]
	);

	const confirm = useCallback(() => {
		if (page == "otros") {
			Session.SetBookingData("extra_events", preorderSelection);
			Session.RedirectAuthenticated("/pago");
		} else {
			Session.SetBookingData("preorder_selection", preorderSelection);

			if (event.offers_upselling == 1) {
				location.href = "/venue/" + slug + "/extras/otros";
			} else {
				Session.RedirectAuthenticated("/pago");
			}
		}
	}, [event, preorderSelection, page, slug]);

	const updateData = useCallback(
		async (event_id) => {
			$("header").addClass("simple");

			if (typeof event_id === "undefined") {
				const id = slug;

				if (id === "") {
					window.location.href = "/";
				} else if (Number.isInteger(id)) {
					event_id = id;
				} else {
					const event = await Events.GetBySlug(id);
					event_id = event.id;
				}
			}

			Events.GetEventSlot(Session.GetBookingData("selected_availability_id")).then((event) => {
				Events.GetAll().then((negocios) => {
					setNegocios(negocios);
				});

				setEvent(event);
			});

			setIsExtraEventsSelection(page == "otros");
		},
		[params]
	);

	const floatSidebar = () => {
		const sidebar = document.querySelector(".booking-sidebar");

		if (sidebar) {
			const sidebarRect = sidebar.getBoundingClientRect();
			const parentRect = sidebar.parentNode.getBoundingClientRect();
			const siblingRect = sidebar.parentNode.querySelector(".preorder-selector-container").getBoundingClientRect();
			sidebar.style.left = siblingRect.right + 15 + "px";
			sidebar.style.width = parentRect.width - siblingRect.width + "px";
			sidebar.style.opacity = 1;
		}
	};

	useEffect(() => {
		updateData();
		$("body").addClass("preorder");
		if (Helper.IsResponsive()) {
			$("header").hide();
		}

		floatSidebar();

		return () => {
			$("body").removeClass("preorder");
			if (Helper.IsResponsive()) {
				$("header").show();
			}
		};
	}, [updateData]);

	useEffect(() => {
		ContentHelper.GetLogoImg("logo", 55).then((logo) => setLogo(logo));
	}, [ContentHelper]);

	useEffect(() => {
		let newTotal = 0;
		preorderSelection.forEach((event) => {
			if (event.full_booking) {
				newTotal += event.price;
			} else {
				newTotal += event.quantity * event.price;
			}

			event.extras?.forEach((extra) => {
				newTotal += extra.qty * extra.price;
			});
		});
		if (newTotal == 0) {
			setFormattedTotal(null);
			setTotal(0);
		} else {
			setFormattedTotal(Helper.FormatAmount(newTotal / 100));
			setTotal(newTotal);
		}
	}, [preorderSelection]);

	useEffect(() => {
		if (event) {
			Helper.SetDocumentTitle(event.name);
			floatSidebar();
		}
	}, [event]);

	useEffect(() => {
		let targetOffset = 0;

		const onMouseWheel = (e) => {
			if (document.body.classList.contains("modal-open")) return;

			floatSidebar();

			let delta = e.deltaY;

			const sidebar = document.querySelector(".booking-sidebar");

			if (!sidebar) return;

			if (e.deltaMode == WheelEvent.DOM_DELTA_LINE) {
				delta /= 10;
			} else if (e.deltaMode == WheelEvent.DOM_DELTA_PAGE) {
				delta /= window.innerHeight;
			}

			const rect = sidebar.getBoundingClientRect();

			if (rect.top >= 150 && rect.top + rect.height <= window.innerHeight - 15) {
				targetOffset = 150;
			} else {
				targetOffset = targetOffset || parseInt(sidebar.style.top) || 150;
				targetOffset -= delta;

				if (delta < 0 && targetOffset > 150) {
					targetOffset = 150;
				}

				if (delta > 0 && targetOffset < window.innerHeight - rect.height - 15) {
					targetOffset = window.innerHeight - rect.height - 15;
				}
			}

			sidebar.style.top = targetOffset + "px";
		};

		window.addEventListener("wheel", onMouseWheel);

		floatSidebar();

		return () => {
			window.removeEventListener("wheel", onMouseWheel);
		};
	}, []);

	if (!event) {
		return <LoadingIndicator />;
	}

	const promo_title = Session.GetBookingData("promo_name");
	let filteredPreorderSelection;

	if (page == "otros") {
		filteredPreorderSelection = preorderSelection.filter((v) => v.reference_id);
	} else {
		filteredPreorderSelection = preorderSelection.filter((v) => !v.reference_id);
	}

	let availability = null;
	JSON.parse(booking.selected_event).events.forEach((event) => {
		event.availability.rows.forEach((av) => {
			if (av.id == booking.selected_availability_id) {
				availability = av;
				availability.event_name = event.nombre;
			}
		});
	});

	let extras_menu = [];
	if (availability && availability.extras_menu) {
		extras_menu = JSON.parse(availability.extras_menu);
	} else if (event.extras_menu) {
		extras_menu = JSON.parse(event.extras_menu);
	} else if (event.negocio.preorder_menu) {
		extras_menu = JSON.parse(event.negocio.preorder_menu);
	}

	if (!extras_menu) {
		if (page == "otros") {
			location.href = "/pago";
		} else {
			location.href = "/venue/" + slug + "/extras/otros";
		}
		return null;
	}

	const event_amount = (event.availability.price != -1 ? event.availability.price : event.price != -1 ? event.price : event.negocio.price) * (event.full_booking ? 1 : booking.pax);
	const extras_amount = booking.preorder_selection?.reduce((acc, v) => acc + v.price * v.quantity, 0) || 0;
	const total_amount = event_amount + extras_amount + total;

	return (
		<PreorderSelectionContext.Provider
			value={{
				preorderSelection,
				changeSelection,
				formattedTotal,
				total
			}}>
			<div className="preorder container">
				<div className="row preorder-custom-header">{page == "otros" ? logo : event.name}</div>
				<div className="row">
					<div className="preorder-selector-container col-md-8 col-sm-12">
						<PreorderSelector params={params} negocios={negocios} event={event} availability={availability} extras_menu={extras_menu} booking={booking} onConfirm={confirm} />
					</div>
					<div className="col-md-4 booking-sidebar">
						<div className="box">
							<div className="event-information">
								<div>
									<div
										className="image"
										style={{
											backgroundImage: "url(/static/images/eventos/" + (event.image_url_alt || event.image_url) + ")"
										}}></div>
									<div className="name">{event.negocio.name}</div>
									<div className="address-name">{event.address ? event.address : event.negocio.address_name}</div>
								</div>
							</div>
							<PreorderTicket event={event} booking={booking} page={page} />
						</div>
						{event && (
							<div className="boton-confirmar-container">
								<a
									className={"btn btn-brown boton-confirmar" + (filteredPreorderSelection.length == 0 && !isExtraEventsSelection ? " sin-extras" : "")}
									onClick={(e) => {
										e.preventDefault();
										confirm();
									}}>
									{filteredPreorderSelection.length > 0 || isExtraEventsSelection ? (
										<>
											<span>Confirmar total</span> <span>{Helper.FormatAmount(total_amount / 100)}</span>
										</>
									) : (
										"Continuar sin extras"
									)}
								</a>
							</div>
						)}
					</div>
				</div>
			</div>
		</PreorderSelectionContext.Provider>
	);
}
