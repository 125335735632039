import React, { useState, useEffect, useReducer, useRef } from "react";
import { Routes, Route, useLocation } from "react-router-dom";

import $ from "jquery";

import MainMenu from "./MainMenu";
import MobileMenu from "./MobileMenu";
import Home from "./Home";
import FichaEvento from "./FichaEvento";
import PlanearEvento from "./PlanearEvento";
import Registro from "./Registro";
import Login from "./Login";
import FormularioEventos from "./FormularioEventos";
import FormularioContacto from "./FormularioContacto";
import FormularioRestaurantes from "./FormularioRestaurantes";
import ContactoGracias from "./ContactoGracias";
import RegistroGracias from "./RegistroGracias";
import Pago from "./Pago";
import ReservaCompletada from "./ReservaCompletada";
import TerminosYCondiciones from "./TerminosYCondiciones";
import PoliticaDePrivacidad from "./PoliticaDePrivacidad";
import PreguntasFrecuentes from "./PreguntasFrecuentes";
import PopupCookies from "./PopupCookies";
import CambiarContrasena from "./CambiarContrasena";
import RegistroActivar from "./RegistroActivar";
import ReservaFallida from "./ReservaFallida";
import MiCuenta from "./MiCuenta";
import CambioEmail from "./CambioEmail";
import Preorder from "./Preorder";
import Admin from "./Admin";
import Partners from "./Partners";
import Helper from "../services/Helper";
import { APIRequest } from "../services/API";
import { HeaderContext } from "../services/HeaderContext";

import { SessionContext } from "../context/SessionContext";
import Session from "../services/Session";
import LoadingIndicator from "./LoadingIndicator";

import { DialogContext } from "../context/DialogContext";
import ConfirmationDialog from "./ConfirmationDialog";

export default function App() {
	const [, forceUpdate] = useReducer((x) => x + 1, 0);
	const location = useLocation();
	const resizeTimeout = useRef(null);
	const [drawHeaderButtons, setDrawHeaderButtons] = useState();
	const [bootstrapped, setBoostrapped] = useState(false);
	const [headerButtons, setHeaderButtons] = useState(null);
	const [session, setSession] = useState();
	const [previousSession, setPreviousSession] = useState();

	const [confirmationDialogVisible, setConfirmationDialogVisible] = useState(false);
	const [confirmationDialogPrompt, setConfirmationDialogPrompt] = useState("");
	const [confirmationDialogYesText, setConfirmationDialogYesText] = useState("Sí");
	const [confirmationDialogNoText, setConfirmationDialogNoText] = useState("No");
	const [confirmationDialogOnAccepted, setConfirmationDialogOnAccepted] = useState(null);
	const [confirmationDialogOnRejected, setConfirmationDialogOnRejected] = useState(null);

	function onResize(e) {
		clearTimeout(resizeTimeout.current);
		resizeTimeout.current = setTimeout(() => {
			forceUpdate();
		}, 100);
	}

	useEffect(() => {
		$("body.scroll-lock").removeClass("scroll-lock");
		window.scrollTo(0, 0);
		Helper.SetDocumentTitle("");
	}, [location]);

	useEffect(() => {
		if (!previousSession && !session) {
			return;
		}

		setPreviousSession(session);

		Session.CheckIsLoggedIn().then(isLoggedIn => {
			if (!isLoggedIn) {
				sessionStorage.clear();
				location.href = "/";
			}
		});
	}, [session]);

	useEffect(() => {
		$(window).on("resize", onResize);

		let scriptA, scriptB;

		Session.Bootstrap().then(() => {
			Session.GetSession().then(session => {
				setSession(session);
				setBoostrapped(true);
			});
		});

		APIRequest("cms/get-analytics-tag").then((response) => {
			if (!response.status || !response.data) return;

			const tag = response.data;

			scriptA = document.createElement("script");
			scriptA.type = "text/plain";
			scriptA.setAttribute("data-service", "google-analytics");
			scriptA.setAttribute("data-category", "statistics");
			scriptA.async = true;
			scriptA.src = "https://www.googletagmanager.com/gtag/js?id=" + tag;
			document.body.appendChild(scriptA);

			scriptB = document.createElement("script");
			scriptB.textContent = `
					window.dataLayer = window.dataLayer || [];
					function gtag(){dataLayer.push(arguments);}
					gtag('js', new Date());
					gtag('config', '${tag}')
			`;
			document.body.appendChild(scriptB);
		});

		const autoTableInterval = setInterval(() => {
			$(".auto-table").each(function () {
				const cellWidths = [];

				$(this)
					.find(".auto-row")
					.each(function () {
						$(this)
							.children()
							.each(function (idx) {
								if ($(this).hasClass("no-auto-width")) return;
								if ($(this).css("flex") != "1 1 0%" && (!cellWidths[idx] || $(this).outerWidth() > cellWidths[idx])) {
									cellWidths[idx] = $(this).outerWidth();
								}
							});
					});

				$(this)
					.find(".auto-row")
					.each(function () {
						$(this)
							.children()
							.each(function (idx) {
								if ($(this).hasClass("no-auto-width")) return;
								$(this).css("min-width", cellWidths[idx]);
							});
						$(this).removeClass("auto-row");
					});

				$(this).find(".no-auto-width").removeClass("no-auto-width");
				$(this).removeClass("auto-table");
			});
		}, 500);

		const sessionCheckInterval = setInterval(() => {
			Session.GetSession().then(session => setSession(session));
		}, 10000);

		return function cleanup() {
			$(window).off("resize", onResize);
			if (scriptA) document.body.removeChild(scriptA);
			if (scriptB) document.body.removeChild(scriptB);
			clearInterval(autoTableInterval);
			clearInterval(sessionCheckInterval);
		};
	}, []);

	if (!bootstrapped) return <LoadingIndicator />;

	return (
		<SessionContext.Provider value={{ session }}>
		<DialogContext.Provider
			value={{
				openDialog: (prompt, yes, no, callback) => {
					setConfirmationDialogPrompt(prompt);
					setConfirmationDialogYesText(yes);
					setConfirmationDialogNoText(no);
					setConfirmationDialogOnAccepted(() => () => {
						callback(true);
						setConfirmationDialogVisible(false);
					});
					setConfirmationDialogOnRejected(() => () => {
						callback(false);
						setConfirmationDialogVisible(false);
					});
					setConfirmationDialogVisible(true);
				}
			}}>
		<HeaderContext.Provider
			value={{
				headerButtons,
				setHeaderButtons: (element) => {
					setHeaderButtons(element);
				}
			}}>
			{!Helper.IsResponsive() && (
				<header className="desktop">
					<MainMenu />
				</header>
			)}
			{Helper.IsResponsive() < 768 && (
				<header className="mobile">
					<MobileMenu />
				</header>
			)}
			<section id="main">
				<Routes>
					<Route path="/" element={<Home />} />
					<Route path="/admin" element={<Admin />} />
					<Route path="/admin/:page" element={<Admin />} />
					<Route path="/admin/:page/:args" element={<Admin />} />
					<Route path="/admin/:page/:args/:args2" element={<Admin />} />
					<Route path="/venue/:slug" element={<FichaEvento />} />
					<Route path="/venue/:slug/extras" element={<Preorder />} />
					<Route path="/venue/:slug/extras/:page" element={<Preorder />} />
					<Route path="/venue/:slug/:availability_id" element={<FichaEvento />} />
					<Route path="/para-empresas" element={<PlanearEvento />} />
					<Route path="/registro" element={<Registro />} />
					<Route path="/login" element={<Login />} />
					<Route path="/contratar-un-evento" element={<FormularioEventos />} />
					<Route path="/contacto" element={<FormularioContacto />} />
					<Route path="/contacto-restaurantes" element={<FormularioRestaurantes />} />
					<Route path="/gracias-por-contactar-con-nosotros" element={<ContactoGracias />} />
					<Route path="/cuenta-creada" element={<RegistroGracias />} />
					<Route path="/activar-usuario" element={<RegistroActivar />} />
					<Route path="/pago" element={<Pago />} />
					<Route path="/reserva-completada" element={<ReservaCompletada />} />
					<Route path="/reserva-fallida" element={<ReservaFallida />} />
					<Route path="/terminos-y-condiciones" element={<TerminosYCondiciones />} />
					<Route path="/politica-de-privacidad" element={<PoliticaDePrivacidad />} />
					<Route path="/preguntas-frecuentes" element={<PreguntasFrecuentes />} />
					<Route path="/cambiar-contrasena" element={<CambiarContrasena />} />
					<Route path="/mi-cuenta" element={<MiCuenta />} />
					<Route path="/partners" element={<Partners />} />
					<Route path="/cambio-email" element={<CambioEmail />} />
				</Routes>
			</section>
			<PopupCookies />
			{confirmationDialogVisible && <ConfirmationDialog prompt={confirmationDialogPrompt} yesButtonText={confirmationDialogYesText} noButtonText={confirmationDialogNoText} onAccepted={confirmationDialogOnAccepted} onRejected={confirmationDialogOnRejected} />}
		</HeaderContext.Provider>
		</DialogContext.Provider>
		</SessionContext.Provider>
	);
}
