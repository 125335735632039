import { useState, useEffect, useCallback, useContext } from "react";
import { useNavigate } from "react-router-dom";

import Events from "../services/Events";
import LoadingIndicator from "./LoadingIndicator";
import ImageUpload from "./ImageUpload";
import JSONEditor from "./JSONEditor";
import Field from "./Field";
import AdminBar from "./AdminBar";
import Bloque from "./AdminBloque";
import MaterialIcon from "./MaterialIcon";
import InputSelect from "./InputSelect";
import BarChart from "./estadisticas/BarChart";

import AdminService from "../services/Admin";
import { APIAdminRequest } from "../services/API";
import Helper from "../services/Helper";
import AccordionTab from "./AccordionTab";
import { DialogContext } from "../context/DialogContext";

export default function AdminEditarNegocio(props) {
	const navigate = useNavigate();
	const [negocio, setNegocio] = useState();
	const [types, setTypes] = useState();
	const [partners, setPartners] = useState();
	const dialogContext = useContext(DialogContext);

	const negocioID = props.negocioID || 0;

	useEffect(() => {
		AdminService.GetTypes().then((types) => {
			setTypes(types.map((type) => type.type));
		});

		AdminService.GetPartners().then((partners) => {
			setPartners(partners);
		});

		document.body.classList.add("bg-grey");

		return () => {
			document.body.classList.remove("bg-grey");
		};
	}, []);

	useEffect(() => {
		if (negocioID == 0) {
			setNegocio({
				id: 0,
				name: "",
				type: types ? types[0] : "",
				email: "",
				address_name: "",
				address: "",
				city: "",
				image_url: "",
				description: "",
				tipo: "",
				info: {
					dias: "",
					horario: ""
				},
				preorder_menu: {},
				iconos: [],
				iconos_detalle: [],
				events: []
			});
		} else {
			Events.GetNegocio(negocioID).then((negocio) => {
				negocio.preorder_menu = JSON.parse(negocio.preorder_menu);
				negocio.iconos = JSON.parse(negocio.iconos);
				negocio.iconos_detalle = JSON.parse(negocio.iconos_detalle);
				setNegocio(negocio);
			});
		}
	}, [negocioID, types]);

	const save = useCallback(notifySaved => {
		dialogContext.openDialog("¿Seguro que quieres guardar los cambios?", "Sí", "No", confirmed => {
			if (confirmed) {
				APIAdminRequest("save-negocio", negocio).then(() => {
					notifySaved(true);
				});
			} else {
				notifySaved(false);
			}
		});
	}, [ negocio ]);

	if (!negocio || !partners) return <LoadingIndicator />;

	const currentPartner = partners.find((partner) => partner.id == negocio.partner_id);
	let currentPartnerString = "";
	if (currentPartner) {
		currentPartnerString = `${currentPartner.name} ${currentPartner.surname} (${currentPartner.login})`;
	}

	return (
		<div className="editar-negocio edit-page">
			<AdminBar onSave={save} />
			<div className="header">
				<h2>{negocio.name ? negocio.name : "Nuevo negocio"}</h2>
				<div
					href="#"
					className="enabled-status"
					onClick={(e) => {
						e.preventDefault();
						const newNegocio = { ...negocio };
						newNegocio.enabled = !newNegocio.enabled;
						setNegocio(newNegocio);
					}}>
					{negocio.enabled && (
						<a href="#">
							<MaterialIcon name="toggle_on" />
						</a>
					)}
					{!negocio.enabled && (
						<a
							href="#"
							onClick={(e) => {
								e.preventDefault();
							}}>
							<MaterialIcon name="toggle_off" />
						</a>
					)}
				</div>
				<div className="delete-negocio-container">
					<a href="#" onClick={(e) => {}}>
						<MaterialIcon name="delete" tooltip="Borrar negocio" />
					</a>
				</div>
			</div>
			<Bloque className="columnas">
				<div className="columna">
					<ImageUpload
						initialValue={negocio.image_url ? "eventos/" + negocio.image_url : ""}
						onChange={(value) => {
							const newNegocio = { ...negocio };
							newNegocio.image_url = e.target.value.match(/eventos\/(.*)/)[1];
							setNegocio(newNegocio);
						}}
					/>
					<p />
					<Field
						placeholder="Días de apertura"
						type="string"
						defaultValue={negocio.info.dias}
						onChange={(value) => {
							const newNegocio = { ...negocio };
							newNegocio.info.dias = value;
							setNegocio(newNegocio);
						}}
					/>
					<Field
						placeholder="Horario de apertura"
						type="string"
						defaultValue={negocio.info.horario}
						onChange={(value) => {
							const newNegocio = { ...negocio };
							newNegocio.info.horario = value;
							setNegocio(newNegocio);
						}}
					/>
				</div>
				<div className="columna">
					{partners && partners.length > 0 && (
						<InputSelect
							className="usuario-administrador"
							placeholder="Administrador/a"
							defaultValue={currentPartnerString}
							options={partners.map((row) => `${row.name} ${row.surname} (${row.login})`)}
							onChange={(value) => {
								const m = value.match(/\((.+?)\)/i);
								if (!m) return;
								const partner = partners.find((partner) => (partner.login = m[1]));
								const newNegocio = { ...negocio };
								newNegocio.partner_id = partner.id;
								setNegocio(newNegocio);
							}}
						/>
					)}
					<div className="columnas">
						<Field
							placeholder="Nombre"
							className="expand"
							type="string"
							defaultValue={negocio.name}
							onChange={(value) => {
								const newNegocio = { ...negocio };
								newNegocio.name = value;
								setNegocio(newNegocio);
							}}
						/>
						<select
							className="desplegable-tipos"
							placeholder="Tipo"
							onChange={(e) => {
								const newNegocio = { ...negocio };
								newNegocio.tipo = e.target.value;
								setNegocio(newNegocio);
							}}>
							{types &&
								types.map((type, idx) => {
									return (
										<option key={idx} value={type}>
											{Helper.UCFirst(type)}
										</option>
									);
								})}
						</select>
					</div>
					<Field
						placeholder="E-mail"
						type="string"
						defaultValue={negocio.email}
						onChange={(value) => {
							const newNegocio = { ...negocio };
							newNegocio.email = value;
							setNegocio(newNegocio);
						}}
					/>
					<Field
						type="string"
						defaultValue={negocio.address_name}
						placeholder="Ubicación"
						onChange={(value) => {
							const newNegocio = { ...negocio };
							newNegocio.address_name = value;
							setNegocio(newNegocio);
						}}
					/>
					<Field
						type="string"
						defaultValue={negocio.address}
						placeholder="Dirección"
						onChange={(value) => {
							const newNegocio = { ...negocio };
							newNegocio.address = value;
							setNegocio(newNegocio);
						}}
					/>
					<Field
						type="string"
						defaultValue={negocio.city}
						placeholder="Ciudad"
						onChange={(value) => {
							const newNegocio = { ...negocio };
							newNegocio.city = value;
							setNegocio(newNegocio);
						}}
					/>
					<Field
						className="editor-descripcion"
						type="text"
						defaultValue={negocio.description}
						onChange={(value) => {
							const newNegocio = { ...negocio };
							newNegocio.description = value;
							setNegocio(newNegocio);
						}}
					/>
				</div>
			</Bloque>
			<Bloque>
				{negocio && (
					<div className="eventos">
						<h2>Eventos</h2>
						<div className="eventos-grid">
							{negocio.events.map((event, eventIndex) => {
								return (
									<div
										className={"evento" + (!event.enabled ? " disabled" : "")}
										key={"evento-" + eventIndex}
										onClick={() => {
											location.href = "/admin/negocios/" + negocio.id + "/" + event.id;
										}}>
										<div className="image-container" style={{ backgroundImage: "url(/static/images/eventos/" + event.image_url + ")" }}></div>
										<div className="details">
											<div className="nombre">{event.nombre}</div>
											<div className="price">{Helper.FormatAmount(event.price / 100)}</div>
										</div>
									</div>
								);
							})}
							<div
								className="evento nuevo-evento"
								onClick={() => {
									location.href = "/admin/negocios/" + negocio.id + "/0";
								}}>
								<MaterialIcon name="add_circle" />
							</div>
						</div>
					</div>
				)}
			</Bloque>
			<Bloque>
				<AccordionTab heading="Extras" className="extras">
					<JSONEditor
						initialValue={negocio.preorder_menu}
						onChange={(data) => {
							const newNegocio = { ...negocio };
							newNegocio.preorder_menu = data;
							setNegocio(newNegocio);
						}}
						associative={true}
						keyTitle="Categoría"
						properties={[{ title: "Descripción", optional: true, key: "description", type: "string" }]}
						listTitle="Productos"
						listProperties={[
							{ title: "Nombre", key: "name", type: "string" },
							{ title: "Precio", key: "price", type: "string" },
							{ title: "Descripción", key: "description", type: "text" },
							{ title: "Imagen", key: "image_url", type: "image" }
						]}
					/>
				</AccordionTab>
				<AccordionTab heading="Iconos" className="iconos">
					<JSONEditor
						initialValue={negocio.iconos}
						onChange={(data) => {
							const newNegocio = { ...negocio };
							newNegocio.iconos = data;
							setNegocio(newNegocio);
						}}
						listTitle="Iconos"
						properties={[
							{ title: "Etiqueta", key: "description", type: "string" },
							{ title: "Icono", key: "name", type: "icon" }
						]}
					/>
				</AccordionTab>
				<AccordionTab heading="Detalles" className="detalles">
					<JSONEditor
						initialValue={negocio.iconos_detalle}
						listTitle="Iconos"
						onChange={(data) => {
							const newNegocio = { ...negocio };
							newNegocio.iconos_detalle = data;
							setNegocio(newNegocio);
						}}
						headingPropertyKey="titulo"
						properties={[
							{ title: "Icono", key: "icono", type: "icon" },
							{ title: "Título", key: "titulo", type: "string" },
							{ title: "Listado", key: "lista", type: "strings" }
						]}
					/>
				</AccordionTab>
			</Bloque>
			<Bloque className="columnas estadisticas" columnCount={2} style={{paddingTop: 75, position: "relative"}}>
				<h2 style={{position: "absolute", left: 25, top: 25}}>Estadísticas</h2>
				<div className="columna" style={{ height: 420 }}>
					<div>
						<h3>Reservas por día de la semana</h3>
						<BarChart singleMode={true} />
					</div>
				</div>
				<div className="columna">
					<h3>Indicadores de rendimiento</h3>
					<div className="kpi-container">
						<div className="item">
							<div className="value">{Helper.FormatAmount(78)}</div>
							<div className="caption">Tícket medio por persona</div>
						</div>
						<div className="item">
							<div className="value">67%</div>
							<div className="caption">Recurrencia</div>
						</div>
						<div className="item">
							<div className="value">45%</div>
							<div className="caption">Conversión upselling</div>
						</div>
						<div className="item">
							<div className="value">66%</div>
							<div className="caption">Conversión cross-selling</div>
						</div>
					</div>
				</div>
			</Bloque>
		</div>
	);
}
